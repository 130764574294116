import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import VModal from "vue-js-modal";
import axios from "axios";
import VuePapaParse from "vue-papa-parse";
import util from "./mixins/util";

Vue.config.productionTip = true;

// コムシスITノジマ環境
// axios.defaults.baseURL = "https://172.16.19.113/api";
// パナソニック様ノジマ環境
// axios.defaults.baseURL = "https://35.189.156.209/api";
// 開発環境
// axios.defaults.baseURL = "http://localhost:8080/api";
// ノジマ様本番環境
// axios.defaults.baseURL = "https://signage.nojima.co.jp/api";
// ITX本番環境
  axios.defaults.baseURL = "https://signage.itx-corp.co.jp/api";

store.dispatch("autoLogin");

Vue.use(VModal); //モダールライブラリ
Vue.use(VuePapaParse); //JSON変換用ライブラリ
Vue.mixin(util); //内製ツール


axios.interceptors.request.use((request) => {
	console.log("intercept:request.url:" + request.url);
	return request;
});

axios.interceptors.response.use(
	(response) => {
		console.log("intercept:response.data.token:" + response.data.token);
		return response;
	},
	(error) => {
		console.log(
			"intercept:response.data.error:" + error.response.data.error
		);
		if (error.response && error.response.data.status === 401) {
			localStorage.removeItem("idToken");

			if (store.state.component != "Login") {
				window.location.href = "/login";
			}
			return Promise.reject(error);
		}
	}
);

// デジタルサイネージシステム・管理画面バージョン
Vue.prototype.$version = "0.1.17";
localStorage.setItem("version", Vue.prototype.$version);

new Vue({
	store,
	router,
	render: (h) => h(App),
}).$mount("#app");

// デバッグ用起動コマンド   >npm run serve -- --port 8081
// リリース用ビルドコマンド >npm run build
